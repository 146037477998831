import { Box } from "@mui/material";


export function ReviewsPage() {
    return (
      <Box my={10} textAlign="center">
        coming soon!
      </Box>
    );
  }
  